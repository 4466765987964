<template>
  <PageWrapper>
    <div id="AccountPage">
      <div class="create_Btn">
        <el-button icon="el-icon-plus" size="mini" type="primary" data-testid="openDemoAccount" @click="addAccount">{{
          $t('common.button.addAccount')
        }}</el-button>
      </div>
      <Empty
        class="empty_box"
        v-if="allMetaDemoAccounts.length === 0 && !isLoading"
        :description="$t('common.field.noAccountData')"
      ></Empty>
      <el-row class="account_cards" :gutter="16" v-else>
        <el-col :xl="8" :md="12" :xs="24" class="account_item" v-for="item in allMetaDemoAccounts" :key="item.index">
          <div :class="['item_box', { inactive_item_box: item.status === 'expired' }]">
            <div class="item_top">
              <div class="top_left">
                <span class="server_category" v-if="item.platform">
                  {{ `MT${item.platform}` }}
                </span>
                <span class="account_num">
                  {{ item.accNum ? item.accNum : '--' }}
                </span>
                <el-divider direction="vertical"></el-divider>
                <span :class="item.status === 'active' ? 'active_color' : 'inactive_color'">
                  {{ item.status | accStatus }}
                </span>
              </div>
              <div class="setting_box" v-if="item.status === 'active'">
                <el-popover placement="bottom-end" trigger="click" :offset="20" popper-class="setting_popover">
                  <ul class="popover_btn_list">
                    <li class="btn_item">
                      <el-button @click="onChangePassword(item)" :data-testid="'resetPassword' + item.platform">{{
                        $t('resetPassword.header')
                      }}</el-button>
                    </li>
                    <li class="btn_item">
                      <el-button @click="onForgotPassword(item)" data-testid="forgotPw">{{
                        $t('common.button.forgetPw')
                      }}</el-button>
                    </li>
                    <li class="btn_item" v-if="item.leverage">
                      <el-button
                        @click="!checkRestrict(item.group) && onChangeLeverage(item)"
                        :data-testid="'platform' + item.platform"
                        >{{ $t('common.button.changeLeverage') }}</el-button
                      >
                    </li>
                    <li class="btn_item">
                      <el-button @click="!checkRestrict(item.group) && onResetBalance(item)">{{
                        $t('common.column.resetBal')
                      }}</el-button>
                    </li>
                  </ul>
                  <span slot="reference" class="setting_icon el-icon-setting"></span>
                </el-popover>
              </div>
            </div>
            <div class="item_center">
              <CurrencyIcon :currency="item.currency ? item.currency : '--'" />
              <span class="equity_box">{{ item.equity | formatNumber }}</span>
              <span class="currency_box">{{ item.currency ? item.currency : '--' }}</span>
            </div>
            <div class="item_bottom">
              <div class="bottom_left">
                <span>{{ item.status === 'active' ? `${item.leverage} : 1` : '--' }}</span>
                <span v-if="item.server">
                  <el-divider direction="vertical"></el-divider>
                  <span>{{ item.server }}</span>
                </span>
                <span>
                  <el-divider direction="vertical"></el-divider>
                  <span>{{ $config.accountTypeMaps[item.accMt4Type] }}</span>
                </span>
              </div>
              <div class="bottom_right">
                <el-button data-testid="depositFunds" @click="getDepositBlacklist()">{{
                  $t('common.button.depositFunds')
                }}</el-button>
                <el-button data-testid="withdrawFunds" @click="getWithdrawalBlacklist()">{{
                  $t('common.button.withdrawFunds')
                }}</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <AddDemoAccount ref="demoAccountRef" @handleReset="handleReset" />

      <!-- CHANGE LEVERAGE 弹框开始 -->
      <ChangeLeverage ref="changeLeverageRef" :showTNC="false" method="demo" @resetTable="getDemo" />
      <!-- CHANGE LEVERAGE 弹框结束 -->

      <!-- 点击 CHANGE PASSWORD  表单弹框开始 -->
      <ChangePassword :changePasswordVisible.sync="changePasswordVisible" :number="number" :isDemo="isDemo">
      </ChangePassword>
      <!-- 点击 CHANGE PASSWORD  表单弹框结束 -->

      <!-- FORGOT PASSWORD 开始 -->
      <ForgotPassword ref="forgotPassword" :isDemo="isDemo" />
      <!-- FORGOT PASSWORD 结束 -->

      <!-- 点击 RESET BALANCE 开始 -->
      <ResetBalance :resetBalVisible.sync="resetBalVisible" :number="number" @resetTable="getDemo"> </ResetBalance>
      <!-- 点击 RESET BALANCE 结束 -->
    </div>
  </PageWrapper>
</template>

<script>
import { apiHomeGet_demo_accs } from '@/resource'
import ResetBalance from '@/components/home/ResetBalance'
import CurrencyIcon from '@/views/home/components/CurrencyIcon.vue'
import AddDemoAccount from '@/views/home/components/AddDemoAccount.vue'
import ChangePassword from '@/views/home/components/ChangePassword.vue'
import ForgotPassword from '@/views/home/components/ForgotPassword.vue'
import ChangeLeverage from '@/views/home/components/ChangeLeverage.vue'
import blackList from '@/mixins/blackList'

export default {
  name: 'HomeDemo',
  components: { ChangeLeverage, ChangePassword, ResetBalance, AddDemoAccount, ForgotPassword, CurrencyIcon },
  mixins: [blackList],
  data() {
    return {
      uuid: this.$store.state.common.uuid,
      allMetaDemoAccounts: [],
      changePasswordVisible: false,
      resetBalVisible: false,
      number: null,
      isDemo: true,
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.common.isLoading
    },
  },
  mounted() {
    this.getDemo()
  },
  methods: {
    handleReset() {
      this.getDemo()
    },
    addAccount() {
      this.$refs.demoAccountRef.visible = true
    },
    onChangePassword(row) {
      this.number = parseInt(row.accNum)
      this.changePasswordVisible = true
    },
    onForgotPassword(row) {
      this.$refs.forgotPassword.forgotPwdClick(parseInt(row.accNum))
    },
    onResetBalance(row) {
      this.resetBalVisible = true
      this.number = parseInt(row.accNum)
    },
    onChangeLeverage(row) {
      this.$refs.changeLeverageRef.leverageDemoClick(row)
    },

    getDemo() {
      apiHomeGet_demo_accs().then(resp => {
        this.allMetaDemoAccounts = resp.data.data
      })
    },
    checkRestrict(val) {
      if (this.$store.state.common.regulator === 'svg' && val === 'TEST_PULS_G_USD') {
        return true
      }

      if (this.$store.state.common.regulator === 'fsa' && val === 'TEST_PUGS_G_USD') {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss">
@import '@/views/home/css/home.scss';
</style>
