<template>
  <div class="reset-balance-dialog">
    <SDialog :visible.sync="visible" :visibleFooter="true">
      <div class="form-box">
        <el-form label-position="top" :model="formLabelAlign" ref="resetBalanceForm" :rules="resetBalanceRules">
          <el-form-item :label="$t('common.field.accNum')">
            <span class="number" v-html="number"></span>
          </el-form-item>
          <el-form-item :label="$t('common.field.resetBalTo')" prop="resetBalTo">
            <el-input autocomplete="false" v-model="formLabelAlign.resetBalTo" data-testid="resetBalTo"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-button @click="disableResetBalanceVisible(false)" data-testid="cancel">
          {{ $t('common.button.cancel') }}
        </el-button>
        <el-button type="primary" @click="resetBalConfirmClick" data-testid="confirm">
          {{ $t('common.button.confirm') }}
        </el-button>
      </template>
    </SDialog>
  </div>
</template>

<script>
import { apiupdate_balance_demo } from '@/resource'

export default {
  name: 'resetBalance',
  props: {
    resetBalVisible: Boolean,
    number: Number,
    resetBalConfirmVisible: Boolean,
  },
  data() {
    const validateResetBalTo = (rule, value, callback) => {
      this.formLabelAlign.resetBalToVerification = false
      if (value == '' || !checkNumber(value) || value < 1 || value > 1000000) {
        callback(new Error(this.$t('common.formValidation.resetBalAmt')))
      } else {
        this.formLabelAlign.resetBalToVerification = true
      }
    }
    const checkNumber = value => {
      let reg = new RegExp('^[0-9]+$')
      return reg.test(value)
    }
    return {
      visible: false,
      formLabelAlign: {
        resetBalTo: '',
        resetBalToVerification: false,
      },
      resetBalanceRules: {
        resetBalTo: [{ required: true, validator: validateResetBalTo, trigger: 'blur' }],
      },
    }
  },
  watch: {
    resetBalVisible(bool) {
      this.visible = bool
    },
    visible(bool) {
      this.disableResetBalanceVisible(bool)
    },
  },
  methods: {
    resetBalConfirmClick() {
      if (this.formLabelAlign.resetBalToVerification) {
        this.updateBalanceDemo()
      }
    },
    disableResetBalanceVisible(bool) {
      this.$refs.resetBalanceForm.resetFields()
      this.formLabelAlign.resetBalToVerification = false
      this.$emit('update:resetBalVisible', bool)
    },
    updateBalanceDemo() {
      apiupdate_balance_demo({
        metaTraderLogin: this.number,
        targetBalance: this.formLabelAlign.resetBalTo,
      })
        .then(resp => {
          this.$emit('resetTable')
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.disableResetBalanceVisible(false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/resetBalance.scss';
</style>
