<template>
  <div id="AddDemoAccount">
    <SDrawer :title="$t('common.field.addDemoAccount')" :visible.sync="visible" custom-class="common_account_drawer">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-position="top">
        <el-form-item :label="$t('common.field.platform')" prop="tradingPlatform" class="platform_form_item">
          <el-radio-group v-model="form.tradingPlatform" @input="changePlatform">
            <el-radio v-for="(item, index) in $config.openLiveAccount.choosePlatform(regulator)" :data-testid="item.value"
              :label="item.value" :key="item.index">
              <img src="@/assets/images-1/mt4.png" v-if="item.value === 'mt4'" class="platform_img" />
              <img src="@/assets/images-1/mt5.png" v-if="item.value === 'mt5'" class="platform_img" />
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('common.field.accountType')" prop="accountType">
          <el-select v-model="form.accountType" :placeholder="$t('common.field.select')" @change="changeAccountType">
            <el-option v-for="item in accountTypeOptions" :key="item.value" :label="item.label" :value="item.value"
              :data-testid="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('common.field.currency')" prop="currency">
          <el-select v-model="form.currency" :placeholder="$t('common.field.select')">
            <el-option v-for="item in $config.openLiveAccount.chooseCurrency(regulator, form.accountType)"
              :key="item.currency" :label="item.value" :value="item.currency" :data-testid="item.currency">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('common.field.leverageRatio')" prop="leverage">
          <el-select v-model="form.leverage" :placeholder="$t('common.field.select')">
            <el-option v-for="item in $config.openLiveAccount.chooseLeverage(regulator)" :key="item" :value="item"
              :data-testid="item">
              <span>{{ item }} : 1</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('common.field.accountBalance')" prop="balance">
          <el-select v-model="form.balance" :placeholder="$t('common.field.select')">
            <el-option v-for="item in $config.openLiveAccount.chooseBalance(regulator)" :key="item.value"
              :value="item.value" :label="item.name" :data-testid="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" @click="submitClick" data-testid="submit">{{ $t('common.button.submit') }}</el-button>
      </template>
    </SDrawer>
    <SDialog :visible.sync="success" mode="simple" :showFooterCancel="false" :title="$t('common.field.tips')"
      @confirm="onConfirm">
      <StatusMessage type="success" :title="$t('promotion.successful')">
        <template #content>
          <p class="home_status_content" v-html="$t('openDemoAdditAcc.succ')"></p>
        </template>
      </StatusMessage>
    </SDialog>
  </div>
</template>

<script>
import { apiCreateDemoAccount } from '@/resource'
export default {
  name: 'AddDemoAccount',
  data() {
    return {
      visible: false,
      form: {
        tradingPlatform: null,
        accountType: null,
        currency: null,
        leverage: null,
        balance: null,
      },
      rules: {
        tradingPlatform: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.platErr') }],
        accountType: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.accTypeErr') }],
        currency: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.currError') }],
        leverage: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.leverageErr') }],
        balance: [{ required: true, trigger: 'blur', message: this.$t('openAdditAcc.balanceErr') }],
      },
      success: false,
    }
  },
  computed: {
    // accountTypeOptions() {
    //   return this.form.tradingPlatform === 'mt4'
    //     ? this.$config.openLiveAccount.mt4DemoChooseType(this.regulator)
    //     : this.$config.openLiveAccount.mt5DemoChooseType(this.regulator)
    // },
    countryCode() {
      return this.$store.state.common.countryCode
    },
    accountTypeOptions() {
      return this.form.tradingPlatform === 'mt4' ? this.showMT4DemoType : this.showMT5DemoType
    },
    showMT4DemoType() {
      return this.$config.openLiveAccount.mt4DemoChooseType(this.regulator).filter(f => {
        if (f.restrictCountries) return !f.restrictCountries.includes(parseInt(this.countryCode))
        if (f.onlyCountries) return f.onlyCountries.includes(parseInt(this.countryCode))
        return f
      })
    },
    showMT5DemoType() {
      return this.$config.openLiveAccount.mt5DemoChooseType(this.regulator).filter(f => {
        if (f.restrictCountries) return !f.restrictCountries.includes(parseInt(this.countryCode))
        if (f.onlyCountries) return f.onlyCountries.includes(parseInt(this.countryCode))
        return f
      })
    },
  },
  methods: {
    changePlatform(val) {
      this.resetType()
      this.resetCurrency()
    },
    changeAccountType() {
      this.resetCurrency()
    },
    resetType() {
      this.form.accountType = null
    },
    resetCurrency() {
      this.form.currency = null
    },
    createDemoAccount() {
      return apiCreateDemoAccount({ ...this.form })
    },
    submitClick() {
      return this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.createDemoAccount().then(resp => {
            if (resp.data.code === 0) this.success = true
          })
        }
      })
    },
    onConfirm() {
      this.success = false
      this.visible = false
      this.$emit('handleReset')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/views/home/css/addAccount.scss';
</style>
